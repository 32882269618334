.Auction-share-detail-popup {
    .modal {
        .modal-main {
            width: 560px;
            padding: 40px;
            border-radius: 15px;
        }
        h2 {
            padding: 0px 0 24px 0px;
        }
    }
    .share-detail-checkbox {
        display: flex;
        align-items: center;
        li {
            margin-right: 50px;
            &:last-child {
                margin-right: 0px;
            }
        }
        .custom-control-input {
            height: 18px;
            width: 18px;
        }
        .custom-control-label {
            font-weight: 500;
            font-size: 16px;
            color: rgba($heading-color, 0.87);
            padding-left: 35px;
            &:before {
                height: 14px;
                width: 14px;
                top: 4px;
            }
            &:after {
                height: 18px;
                width: 18px;
                top: 4px;
            }
        }
    }
    .css-1qprcsu-option {
        background: #f9fafd;
        input {
            opacity: 0;
            height: 0px;
            width: 0px;
            margin-right: 0px !important;
        }
        &:after {
            content: "\e927";
            font-family: dc !important;
            font-size: 20px;
            color: #0bbddd;
            position: absolute;
            right: 20px;
        }
    }
    .css-1vr111p-option {
        line-height: normal;
        input {
            opacity: 0;
            height: 0px;
            width: 0px;
            margin-right: 0px !important;
        }
        &:hover {
            background: #f9fafd;
            &:after {
                content: "\e927";
                font-family: dc !important;
                font-size: 20px;
                color: #0bbddd;
                position: absolute;
                right: 20px;
            }
        }
    }
    .css-48ayfv + div {
        width: 100%;
    }
    .select-item-list {
        ul {
            font-weight: normal;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                background: #ffffff;
                border: 1px solid #dbdcde;
                border-radius: 16px;
                height: 32px;
                padding-left: 15px;
                display: flex;
                align-items: center;
                font-weight: normal;
                display: flex;
                align-items: center;
                margin-right: 8px;
                margin-top: 10px;

                .ic-clearclose {
                    height: 20px;
                    width: 20px;
                    min-height: 20px;
                    min-width: 20px;
                    background: rgba(227, 231, 241, 0.6);
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    margin-right: 5px;
                    cursor: pointer;
                }
                span {
                    font-size: 12px;
                    color: rgba($heading-color, 0.7);
                    padding-right: 10px;
                }
                &.more-data {
                    border-color: #0bbddd;
                    padding: 0px 14px;
                    span {
                        color: $heading-color;
                        padding-right: 0px;
                    } 
                }
            }
        }
    }
    .buyer-dropdown {
        .css-1v99tuv {
            // padding-left: 50px;
            position: relative;
            top: -1px;
        }
        .label-txt {
            font-weight: normal;
            font-size: 13px;
            color: $heading-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
        }
    }
    fieldset {
        margin-top: 20px;
        textarea {
            height: 120px;
        }
        input.others-title {
            margin-bottom: 0px;
        }
    }
    .select-drop-down {
        .form-label {
            top: -0px;
        }
    }
    .css-1uccc91-singleValue {
        padding-left: 6px;
    }
    .btn-send {
        margin-top: -6px;
        .btn-primary {
            min-width: 160px;
        }
    }
}

.css-1vr111p-option {
    line-height: normal;
    display: flex !important;
    align-items: center;
}

.sms-email-sec { 
    .buyer-dropdown {
        .css-1v99tuv {
            padding-left: 28px;
        }
        .css-48ayfv{
            .css-1v99tuv {
                padding-left: 35px;
            }
        }
        .active-label {
            .css-1v99tuv {
                padding-left: 0px;
            }
            .css-48ayfv{
                .css-1v99tuv {
                    padding-left: 7px;
                }  
            }
        }
        .material{
            .sms-to-label {
                &.form-label:before {
                    top:11px;
                }
            }
        }
        
    }
}