.inventrios-data-table {
    background: $white;

    table {
        table-layout: fixed;
        background: $white;
        width: calc(100% + 60px);
        margin-left: -30px;
        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;
                th {
                    padding: 15px 14px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;
                    &:first-child {
                        border-radius: 6px 0 0 0px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        border-radius: 0px 6px 0px 0;
                        padding-right: 30px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);
                td {
                    padding: 24px 14px 20px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;
                    vertical-align: top;

                    &:first-child {
                        // padding-top: 19px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        //   border-bottom: 0px;

                        padding-right: 30px;
                    }
                    .react-datepicker {
                        width: 141%;
                    }
                }

                &:hover {
                    // background: rgba(239, 242, 249, 0.4);
                    .btn-line {
                        background: $accent-color;
                        color: $white;
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
        th,
        td {
            &:nth-child(1) {
                width: 25%;
            }
            &:nth-child(2) {
                width: 15%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 22.5%;
            }
            &:nth-child(5) {
                width: 22.5%;
            }
        }
    }
    .cars-detail {
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: $heading-color;
        }
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: rgba($heading-color, 0.6);
                padding: 3px 0px;
                &:after {
                    content: "";
                    background: rgba($heading-color, 0.3);
                    height: 3px;
                    width: 3px;
                    border-radius: 100%;
                    display: inline-block;
                    margin: 0px 8px;
                    position: relative;
                    top: -2px;
                    min-width: 3px;
                    min-height: 3px;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .input-sec {
        .material {
            .form-input {
                margin-bottom: 0px;
            }
            .form-label:before {
                top: 11px;
            }
        }
        .material .form-input:not(:placeholder-shown) ~ label:before {
            top: -10px;
            font-size: 11px;
        }
        .form-input:focus ~ label:before {
            top: -10px;
            font-size: 11px;
        }
    }
    .data-filed {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        fieldset {
            width: calc(50% - 5px);
        }
        .custom-control {
            // left: -33px;
            .custom-control-label::before {
                height: 14px;
                width: 14px;
            }
            .custom-control-label::after {
                height: 18px;
                width: 18px;
            }
        }
        &.auto-time-sec {
            justify-content: inherit;
            fieldset {
                &:nth-child(1) {
                    margin-right: 5px;
                }
                &:nth-child(2) {
                    margin-left: 5px;
                }
                &:nth-child(3) {
                    margin-right: 5px;
                }
            }
            .custom-checkbox {
                margin-left: 5px;
            }
        }
    }
    .datepicker input {
        background-color: $white;
    }
    .select-drop-down,
    .create-date {
        .material {
            .form-label {
                &:before {
                    font-size: 11px;
                    top: -10px;
                }
            }
        }
    } &.auction-inventory-cls{
        th,td {&:nth-child(1){
            width: 5%;
        }}
        .custom-control-label::before {
            width: 14px;
            height: 14px;
          }
        .custom-control-input {
        height: 18px;
        width: 18px;
        }
    }
}
.replicate-popup {
    .modal-main {
        width: 380px;
        padding: 30px 40px;
        .replace-txt {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $heading-color;
        }
        .yes-no-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 24px;
            button {
                min-width: 110px;
                &.btn-primary {
                    margin-left: 10px;
                }
            }
        }
        .modal-body {
            overflow-y: inherit;
        }
    }
    .close_icn {
        display: none;
    }
}
.update-btn {
    border-top: 1px solid rgba($heading-color, 0.06);
    width: calc(100% + 60px);
    margin-left: -30px;
    padding: 10px 30px;
}

.add-vechile-popup {
    h2 {
        padding-bottom: 20px !important;
    }
    .yes-no-btn {
        display: block;
        margin-top: 20px;
    }
    .css-48ayfv + div {
        position: relative !important;
    }
    .css-1vr111p-option,
    .css-1qprcsu-option {
        input {
            opacity: 0;
            height: 0px;
            width: 0px;
            margin-right: 0px !important;
        }
        &:after {
            content: "\e908";
            position: absolute;
            font-size: 12px;
            color: #000;
            font-family: dc !important;
            right: 0;
            background: rgba(227, 231, 241, 0.6);
            height: 20px;
            width: 20px;
            border-radius: 100%;
            text-align: center;
            line-height: 20px;
            cursor: pointer;
        }
    }
    .css-1jllj6i-control,
    .css-6y1x9t-control {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .css-1v99tuv,
    .css-1gpjby2 {
        position: relative;
        top: -2px;
    }
    .css-ik6y5r {
        box-shadow: none !important;
    }
    .css-1vr111p-option,
    .css-1qprcsu-option {
        border-bottom: 1px solid rgba($heading-color, 0.06);
    }
    .option-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        margin-bottom: 10px;
        display: block;
    }
}
