.top-heading-action-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  .action-btn {
    display: flex;
    button:disabled {
      cursor: not-allowed;
    }
    .btn-line {
      display: flex;
      align-items: center;
      margin-left: 10px;
      i {
        font-size: 22px;
      }
      .ic-share1 {
        font-size: 18px;
      }
      span {
        position: relative;
        top: 1px;
        margin-left: 10px;
      }
    }
  }
}
