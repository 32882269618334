.vechile-table-data {
  .custom-control-label::before {
    width: 14px;
    height: 14px;
  }
  .custom-control-input {
    height: 18px;
    width: 18px;
  }
}

.vechile-table-data {
  background: $white;
  border-radius: 6px;
  table {
    table-layout: fixed;
    thead {
      tr {
        background: #e3e7f1;
        border-radius: 6px;
        th {
          padding: 15px 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: $heading-color;
          white-space: nowrap;
          text-align: left;
          &:first-child {
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba($heading-color, 0.06);
        td {
          padding: 13px 10px;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: rgba($heading-color, 0.6);
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          //   border-bottom: 0px;
        }
        &:first-child {
          td {
            padding-top: 19px;
          }
        }
        &:hover {
          background: rgba(239, 242, 249, 0.4);

          td {
            color: $heading-color;
          }
        }
      }
    }
    .ph-no {
      display: block;
    }
    .name-txt {
      white-space: nowrap;
    }
    th,
    td {
      &:nth-child(1) {
        width: 4%;
      }
      &:nth-child(2) {
        width: 11.5%;
      }
      &:nth-child(3) {
        width: 6.5%;
      }
      &:nth-child(4) {
        width: 13.2%;
      }
      &:nth-child(5) {
        width: 11.1%;
      }
      &:nth-child(6) {
        width: 9%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 16%;
      }
      &:nth-child(9) {
        width: 12.5%;
      }
      &:nth-child(10) {
        width: 13%;
      }
      &:nth-child(11) {
        width: 13%;
      }
      &:nth-child(12) {
        width: 11%;
      }
      &:nth-child(13) {
        width: 10%;
      }
    }
  }
  .btn-view {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 29px;
    color: $heading-color;
    a {
      font-size: 12px;
      color: $white;
      color: $heading-color;
      &:hover {
        color: $white;
      }
    }
  }
  .edit-zip-btn {
    display: flex;
    align-items: center;
    .btn-view {
      cursor: pointer;
      &:hover {
        background: $accent-color;
        color: $white;

        a {
          color: $white;
        }
      }
      .ic-upload {
        font-size: 17px;
        margin-right: 1px;
      }
    }
  }
}
.sorting_asc {
  &:after {
    content: "\e925";
    font-family: dc !important;
    transform: rotate(-90deg);
    display: inline-block;
    margin-left: 3px;
    font-size: 15px;
  }
}
