.top-ribben-search {
  background: $white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  .vechile-list-form {
    display: flex;
    align-items: center;
    fieldset {
      padding: 7px 0px;
    }
    .material {
      margin-right: 8px;
      .form-input {
        margin-bottom: 0px;
      }
    }
    .search-bx {
      width: 16.5%;
    }
    .seller-opt {
      width: 14%;
    }
    .post-action-opt {
      width: 16.5%;
    }
    .model-opt {
      width: 15.75%;
    }
    .create-date {
      width: 13.07%;
    }
    .registartion-date {
      width: 14.21%;
    }
    .created-opt {
      width: 14.02%;
    }
    .multiselect-dropDown {
      .auction-dropdown__single-value {
        font-size: 12px !important;
        max-width: calc(100% - 45px) !important;
      }
      .auction-dropdown__menu-list {
        white-space: normal;
      }
    }
  }
}

.css-1wa3eu0-placeholder {
  font-size: 12px !important;
  font-weight: normal !important;
  color: $heading-color !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tlfecz-indicatorContainer {
  color: $heading-color !important;
}

.css-yk16xz-control {
  height: 42px;
  border-color: #bbbbbb !important;
  border-radius: 8px !important;
  min-height: 42px !important;
}
.css-1fhf3k1-control {
  height: 42px;
  min-height: 42px !important;
}
.css-1pahdxg-control {
  border-color: #bbbbbb !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 42px;
  min-height: 42px !important;
}
.css-1pahdxg-control:hover {
  border-color: #bbbbbb !important;
  box-shadow: none;
  outline: none;
  height: 42px;
  border-color: #bbbbbb !important;
  border-radius: 8px !important;
  min-height: 42px !important;
}
.css-1wa3eu0-placeholder {
  font-size: 12px;
  font-weight: normal;
}
.css-tlfecz-indicatorContainer {
  padding: 4px !important;
}
.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
  color: #000 !important;

  &:hover {
    color: $heading-color !important;
  }
}

.react-datepicker__header__dropdown {
  padding: 10px;
  select {
    appearance: auto;
    background: transparent;
    border: 0px;
  }
}

.btn-submit-reset {
  width: 12%;
  .btn-primary {
    min-width: 88px;
    margin-right: 10px;
  }
  .btn-reset {
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: 0px;
    color: $heading-color;
    cursor: pointer;
  }
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #0bbddd !important;
}

.multiselect-dropDown {
  .css-1r4vtzz,
  .css-48ayfv {
    height: 42px;
    border: 1px solid #bbbbbb !important;
    border-radius: 8px !important;
    background: transparent;
    width: 100%;
    box-shadow: none;
  }
  .css-1r4vtzz {
    padding-left: 15px !important;
  }
  .css-1jllj6i-control {
    // width: 100%;

    box-shadow: none;
  }
  .css-6y1x9t-control {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none;
    &:hover {
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none;
    }
  }
  .css-1v99tuv,
  .css-1gpjby2 {
    font-size: 12px;
    position: relative;
    top: -2px;
    font-weight: normal !important;
    color: #000 !important;
  }

  input {
    appearance: auto;
  }
  .css-1qprcsu-option {
    font-weight: normal;
  }
  .css-4ljt47-MenuList {
    white-space: nowrap;
  }
  .css-9gakcf-option {
    background: $accent-color;
  }
}

.datepicker {
  input {
    width: 100%;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 100%;
    padding: 11px 15px;
    font-weight: 400;
    font-size: 12px;
    background: url(../../images/date-icon.png) no-repeat right 0px center;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: $heading-color;
    font-size: 12px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $heading-color;
    font-size: 12px;
  }

  ::placeholder {
    color: $heading-color;
    font-size: 12px;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0bbddd !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0bbddd !important;
}

.btn-submit-reset-auction-tabs {
  width: 30%;
  .btn-primary {
    min-width: 88px;
    margin-right: 10px;
  }
  .btn-reset {
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: 0px;
    color: $heading-color;
    cursor: pointer;
  }
}

.react-datepicker-time__input {
  background: none !important;
}

.action-monitor-sec {
  .top-ribben-search {
    .vechile-list-form {
      .seller-opt {
        width: 100%;
      }
    }
    .btn-submit-reset {
      display: flex;
    }
  }
}

.vehicle-listing-box {
  .top-ribben-search {
    .vechile-list-form {
      flex-wrap: wrap;
    }
  }
}