.edit-option-tab {
    .nav-pills {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;
        .nav-item {
            margin-right: 40px;
            .nav-link {
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                border-bottom: 2px solid transparent;
                padding-bottom: 5px;
                &.active {
                    color: rgba($accent-color, 0.87);
                    font-weight: 500;
                    border-color: #0bbddd;
                }
            }
        }
    }
}
.auction-tab {
    position: relative;

    .top-right-btn {
        display: flex;
        position: absolute;
        top: 0px;
        right: 158px;
        .search-bx {
        }
        .action-btn {
            display: flex;
            align-items: center;
            .btn-line {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                i {
                    font-size: 18px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.edit-option-tab {
    padding-top: 24px;
}

.d-block {
    display: block;
}

.add-auction-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .action-btn {
        margin-bottom: 30px;
        .btn-line {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            i {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }
}

.add-auction-form {
    .auction-dropdown__indicators {
        .css-tlfecz-indicatorContainer {
            color: #bbbbbb !important;
        }
        .css-1gtu0rj-indicatorContainer {
            color: #bbbbbb !important;
        }
        // svg {
        //     display: block;
        // }
        // &:after {
        //     display: none;
        // }

        .css-1okebmr-indicatorSeparator {
            display: block;
        }
    }
}

.auction-dropdown__single-value {
    font-size: 12px !important;
}

.active-label {
    .datepicker {
        input {
            padding-left: 17px !important;
        }
    }
}

.checkbox-filed,
.inventrios-data-table {
    .custom-control-label::before {
        top: 1px;
    }
}
