.pagination-bx {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    li {
      margin: 0px 3px;
      a {
        color: rgba($heading-color, 0.5);
        font-size: 12px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        border-radius: 6px;
      }
      &.active,
      &:hover {
        a {
          border-color: $accent-color;
          color: $heading-color;
          font-weight: 500;
        }
      }
      &.pre {
        background: #eff2f9;
        border-radius: 6px;
        a {
          font-size: 20px;
        }
      }
      &.next {
        background: #eff2f9;
        border-radius: 6px;
        a {
          font-size: 20px;
        }
      }
    }
  }
}
