.view-timeline-popup {
  .modal-main {
    border-radius: 8px;
    width: 600px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 24px 0px;
    }
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: 350px;
            border-radius: 8px 0 0 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            width:75px;
          }
          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }
          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }
        &:hover {
          background: #bff8fc;
        }
      }
    }
  }
}
