.auction-buyer-outer {
    border-bottom: 1px solid rgba($heading-color, 0.06);
    display: inline-block;
    width: calc(100% + 30px);
    margin-bottom: 10px;
    margin-left: -15px;
    padding: 0px 15px 10px;
}
.auction-buyer-sec {
    .add-buyers-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba($heading-color, 0.87);
        display: block;
        padding: 0px 0 16px;
    }
    .buyer-list-bx {
        background: #ffffff;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        height: 412px;
        margin-bottom: 20px;
        border-bottom: 1px solid rga;
    }
    .select-all-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 10px 20px;
        border-bottom: 1px solid rgba($heading-color, 0.1);
        margin-bottom: 20px;
        .search-bx {
            input {
                height: 36px;
                border: 1px solid #bbbbbb;
                width: 234px;
            }
            i {
                position: absolute;
                left: 20px;
                font-size: 22px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .custom-control-label {
        font-weight: normal;
        font-size: 13px;

        color: $heading-color;
        &:before {
            width: 14px;
            height: 14px;
        }
    }
    .custom-control-input {
        height: 16px;
        width: 16px;
    }
    .buyers-added-list {
        padding-left: 20px;
        padding-right: 10px;
        height: 100%;

        ul {
            height: calc(100% - 165px);
            overflow-y: auto;
            display: inline-block;
            width: 100%;
            li {
                .custom-control-label {
                    line-height: 35px;
                }
            }
        }
        .btn-primary {
            min-width: 150px;
            margin: 20px 0;
        }
    }
    .buyer-no-data-bx {
        height: calc(100% - 77px);
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
        width: 100%;
        justify-content: center;
        flex-direction: column;

        img {
            opacity: 0.2;
        }
        &.no-vechile-img {
            img {
                opacity: 0.8;
            }
        }
        .no-img-txt {
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            display: block;
            text-align: center;
            color: rgba($heading-color, 0.87);
        }
    }
    .buyer-heading {
        margin-top: 30px;
    }
}

button:disabled,
button[disabled] {
    border: 1px solid rgba($heading-color, 0.3);
    background-color: rgba($heading-color, 0.3);
    color: #fff;
    box-shadow: none;
}

.btn-add-auction {
    .btn-primary {
        min-width: 350px;
        margin-top: 20px;
    }
}
