.upload-file-name {
  h4,
  .upload-img-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $heading-color;
    padding: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 7px;

    i {
      background: #fff;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      font-size: 12px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
  .btn-primary {
    margin-bottom: 40px !important;
    background: #fff;
    color: #000;
    box-shadow: none;
    border: 1px solid #8c8c8c;
    min-width: 195px;
  }
  .upload-file-img {
    display: flex;
    align-items: flex-start;
    padding: 20px 20px;
    width: 100%;
    justify-content: center;

    span.suceess-icn {
      display: block;
      min-width: 18px;
      position: relative;
      top: 5px;
    }
    h4 {
      padding-top: 0px;
      white-space: normal;
      flex-wrap: wrap;
      word-break: break-all;
    }
    .ic-clearclose {
      background: #fff;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 100%;
      font-size: 12px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      position: relative;
      top: 5px;
    }
  }
  .file-btn {
    margin: 13px 0px 30px;
  }
  .or-txt {
    text-transform: uppercase;
    padding-top: 10px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;

  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .close_icn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
  }
  .modal-body {
    max-height: 450px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
}

.image-uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 0;
  .display-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eff2f9;
    min-height: 323px;
    width: 100%;
    padding-top: 30px;
    border-radius: 8px;
    &:hover,
    &.drag-over {
      .icon-text-box {
        // background-color: #96a599;
        cursor: pointer;
        // color: #fff;
        // text-shadow: 0px 1px 0 #999;
        .upload-icon {
          // transform: translateY(-20px);
          // animation: bounce 1s ease-in-out infinite;
        }
      }
    }
    .icon-text-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-shadow: 0px 0px 0 #999;
      transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;
      .upload-icon {
        width: 60px;
        height: 60px;
        margin: 0;
        box-sizing: border-box;
        transform: translateY(0px);
        transition: transform 0.2s ease-in-out;
        background: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 38px;
        }
      }
      .upload-button,
      .cancel-upload-button {
        margin: 0 10px;
        position: relative;
        z-index: 9999;
      }

      // .error-notification {
      //   animation: fadeInDown 0.5s;
      //   position: absolute;
      //   top: 20px;
      //   background-color: #dfb852;
      //   border-radius: 5px;
      //   color: #444;
      //   padding: 5px 10px;
      //   p {
      //     margin: 0;
      //     text-shadow: none;
      //   }
      // }
    }
    .upload-image-input {
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      // height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
.upload-txt {
  padding-top: 24px;
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $heading-color;
    padding-bottom: 7px;
  }
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: rgba($heading-color, 0.5);
    padding: 0px 50px;
    display: block;
  }
  .or-txt {
    text-transform: uppercase;
    padding-top: 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $heading-color;
  }
  .error-msg {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #e02020;
    opacity: 1;
    display: block;
    padding: 5px 50px;
  }
  .file-btn {
    margin: 13px 0px 30px;
  }
}

.file-btn {
  min-width: 177px;
  box-shadow: none;
  margin: 23px 0px 30px;
}
.upload-again-btn {
  margin-top: 15px;
}

.upload-vehicle-popup {
  .modal-main {
    width: 943px;
    padding: 40px;
    border-radius: 15px;
  }
  .upload-heading {
    width: calc(50% - 20px);
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: rgba($heading-color, 0.87);
      display: block;
      padding-top: 30px;
    }
  }
  .modal-body {
    display: flex;
    justify-content: space-between;
    max-height: 530px !important;
  }
  &.single-vechile-popup {
    .modal-main {
      width: 500px;
    }
    .upload-heading {
      width: 100%;
    }
  }
}
.upload-inventory-panel {
  .ic-file-icon {
    border: 1px dashed #000;
  }
  .upload-txt {
    text-align: center;
  }
  .or-txt {
    padding-top: 10px;
  }
  .file-btn {
    margin-top: 13px;
  }
  .display-box {
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      cursor: pointer;
    }
  }
}

.download-txt {
  font-size: 12px;
  line-height: 22px;
  font-weight: normal;
  color: rgba($heading-color, 0.6);
  margin-top: 12px;
  a {
    text-decoration: underline;
    color: $heading-color;
  }
}
.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  .btn-primary {
    min-width: 260px;
  }
}
.upload-file-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  span.suceess-icn {
    height: 18px;
    width: 18px;
    background: #44d7b6;
    border-radius: 100%;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
  }
}
