.edit-vechile-heading {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: $heading-color;
  }
}
.edit-file-upload-bx {
  margin-bottom: 20px;
}
.edit-vehile-sec {
  background: $white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  display: inline-block;
  width: 100%;

  .image-uploader-wrapper {
    padding-top: 0px;
  }
  .upload-txt {
    .or-txt {
      padding-top: 10px;
    }
    .file-btn {
      margin-top: 15px;
    }
  }
}
.edit-vechile-list {
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding-top: 25px;
    color: rgba($heading-color, 0.87);
    padding-bottom: 20px;
    clear: both;
  }
  fieldset {
    margin-bottom: 25px;
  }
  .form-select-bx .form-label {
    top: -21px;
  }
  .edit-vechile-detail-form {
    .error-txt {
      position: absolute;
      bottom: -17px;
      left: 0px;
      font-size: 10px;
      color: #ff2e2e;
    }
    .error {
      input {
        border: 1px solid #ff2e2e;
      }
      .css-yk16xz-control {
        border: 1px solid #ff2e2e !important;
      }
    }
    .custom-control-label {
      &::before {
        width: 14px;
        height: 14px;
      }
    }
    .checkbox-filed {
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        cursor: pointer;
      }
      .custom-control-label {
        cursor: pointer;
      }
    }
  }
}
.edit-form {
  .material {
    .form-input {
      margin-bottom: 0px;
    }
    .form-label {
      &:before {
        top: 11px;
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .datepicker-cal {
    .form-label {
      top: -21px;
    }
  }
  .css-1uccc91-singleValue {
    padding-left: 5px !important;
  }
  .css-1wa3eu0-placeholder {
    padding-left: 5px !important;
  }
  .css-107lb6w-singleValue {
    padding-left: 5px !important;
  }
  textarea {
    width: 100%;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 100%;
    padding: 11px 15px;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 20px;
    height: 120px;
  }
}

.Summary-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .btn-line {
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      margin-right: 5px;
    }
    span {
      position: relative;
      top: 2px;
    }
  }
}
.btn-submit-cancel {
  margin-top: 30px;

  .btn-line {
    min-width: 174px;
    margin-left: 20px;
  }
}

.vechile-gallery {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #e6e9ef;
    border-radius: 8px;
    padding: 16px 10px 0;
    max-height: 270px;
    overflow-y: auto;
    margin: 0px -8px;
    li.vehicle-lightbox-slider{
      cursor: pointer;
    }
    li {
      height: 105px;
      width: 156px;
      background: #c4c4c4;
      border-radius: 8px;
      min-width: 156px;
      min-height: 105px;
      margin: 0px 8px 16px;
      position: relative;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .ic-clearclose {
        background: #1f1f1f;
        height: 20px;
        width: 20px;
        position: absolute;
        right: -6px;
        top: -6px;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
      }
      &.add-more-vechile {
        background: #f7f8fc;
        border: 1px solid #cdcfd9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* flex-wrap: nowrap; */
        flex-direction: column;
        span {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: rgba($heading-color, 0.8);
          display: block;
          padding-top: 10px;
        }
        .ic-add {
          background: $white;
          height: 40px;
          width: 40px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}
.css-1uccc91-singleValue ~ .form-label {
  top: -50px !important;
}

.material {
  &.animation-effect {
    .form-label {
      top: -0px !important;
    }
  }
  &.active-label {
    .form-label {
      top: -21px !important;
    }
  }
}

.top-ribben-search {
  .material {
    &.animation-effect {
      .form-label {
        top: -3px !important;
      }
    }
  }
}
.auction-dropdown__clear-indicator {
  &:after {
    content: "\e908";
    position: absolute;
    right: 34px;
    color: rgba($heading-color, 0.7);
    font-family: dc !important;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.auction-dropdown__dropdown-indicator:after {
  content: "\e917";
  position: absolute;
  right: 3px;
  color: rgba($heading-color, 0.7);
  font-family: dc !important;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.auction-dropdown__indicator svg {
  display: none;
}

.auction-dropdown__indicator-separator {
  position: relative;
  right: 20px;
}

.auction-dropdown__clear-indicator + .auction-dropdown__indicator-separator {
  position: relative;
  right: 20px;
  display: block;
}
.multiselect-dropDown {
  .css-1gpjby2 {
    svg {
      display: none;
    }
    &:after {
      content: "\e917";
      position: absolute;
      right: -8px;
      color: rgba($heading-color, 0.7);
      font-family: dc !important;
      font-size: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .css-48ayfv + DIv {
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    margin-top: 0px !important;
  }
  .css-1uccc91-singleValue {
    font-size: 12px !important;
    max-width: calc(100% - 11px) !important;
  }
  .css-26l3qy-menu {
    width: auto;
  }
  .css-9pfaa-MenuList {
    white-space: nowrap;
    font-size: 12px !important;
  }
  .css-11unzgr {
    font-size: 12px;
  }
}

.css-1fhf3k1-control {
  border-radius: 8px !important;
}

.delete-icn {
  height: 42px;
  border-radius: 8px;
  .btn-line {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }
  i {
    font-size: 22px;
  }
  span {
    position: relative;
    top: 2px;
    margin-left: 10px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    font-weight: 500;
    cursor: pointer;
  }
}

.css-1uccc91-singleValue {
  padding-left: 5px;
}
.css-109onse-indicatorSeparator {
  display: none;
}

.seller-opt {
  .material.active-label .form-label {
    top: -23px !important;
  }
}


.react-select__value-container--has-value .css-1wa3eu0-placeholder {
  top: -6px;
  background: $white;
  transition: all 0.15s ease-in-out;
  margin-left: 3px;
  font-size: 12px;
  padding: 0 3px;
  width: auto;
}

.react-select__control--menu-is-open .css-1wa3eu0-placeholder {
  top: -6px;
  background: $white;
  transition: all 0.15s ease-in-out;
  margin-left: 3px;
  font-size: 12px;
  padding: 0 3px;
  width: auto;
}

 
  .react-select__value-container{
    overflow: inherit !important;
  }
 