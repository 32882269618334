.login-left-panel {
  width: 593px;
  height: 100vh;

  .login-bg {
    height: 100%;
    position: relative;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    .logo-img {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0px;
      margin: 0 auto;
      text-align: center;
      margin-left: -90px;
      img {
        width: auto;
      }
    }
  }
}
.login-right-panel {
  background: $white;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  padding-left: 20px;
  padding-top: 120px;
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: $heading-color;
  }
  .login-tab {
    .nav-pills {
      display: inline-flex;
      padding: 5px;
      margin-top: 20px;
      background: #f1f5fa;
      border-radius: 100px;
      padding: 5px;
      .nav-item {
        margin-right: 0px;
        display: inline-flex;
        .nav-link {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #1f1f1f;
          padding: 9px 27px;

          &.active {
            background: #ffffff;
            box-shadow: 0px 1px 3px rgba(27, 57, 90, 0.1);
            border-radius: 100px;
          }
        }
      }
    }
    .tab-pane {
      padding-top: 32px;
      .material {
        .form-input {
          width: 360px;
          padding: 15px 45px 15px 60px;
          font-size: 13px;
          font-weight: 500;
        }
        .otp-time {
          font-size: 11px;
          line-height: 16px;
          color: rgba($heading-color, 0.5);
          position: absolute;
          right: 15px;
          top: 18px;
        }
        .form-label {
          &:before {
            left: 60px;
          }
        }
        .form-input:focus ~ label:before {
          top: -8px;
        }
        .icn-login {
          position: absolute;
          left: 23px;
          top: 14px;
          color: $heading-color;
          font-size: 21px;
        }
      }
    }
  }
  .password-show {
    font-size: 20px;
    position: absolute;
    right: 18px;
    top: 12px;
  }
}
.login-outer {
  background: $white;
  // border-radius: 20px;
  display: flex;
  width: 100%;
  .error {
    input {
      border: 1px solid #ff2e2e;
    }
    .erroe-txt {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: #ff2e2e;
      display: block;
      margin-top: -16px;
      margin-bottom: 20px;
    }
  }
}

.otp-success-icon {
  background: #34c48b;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  color: $white;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 18px;
  right: 16px;
}

.tab-content > .tab-pane {
  display: none;
}
.fade:not(.show) {
  opacity: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
.tab-content > .active {
  display: block;
}
.login-heading {
  span {
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    color: #1f1f1f;
    display: block;
  }
  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #1f1f1f;
  }
}

.forget-password {
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  display: block;
  color: $heading-color;
  position: relative;
  top: -16px;
}

.login-right-panel {
  .captcha-filed {
    .password-show {
      top: 10px;
      .reload {
        position: relative;
        top: -10px;
        cursor: pointer;
      }
    }
  }
}

.auction-dropdown__control--is-disabled {
  background-color: transparent !important;
  .auction-dropdown__dropdown-indicator:after {
    color: rgba(153, 153, 153, 0.7) !important;
  }
}

.auction-dropdown__single-value--is-disabled {
  color: rgba(153, 153, 153, 0.7) !important;
}

.password-txt {
  &[type="password"] ~ .password-show .ic-show-password {
    display: none;
  }
  &[type="text"] ~ .password-show .ic-hide-password {
    display: none;
  }
}

.password-show {
  .ic-hide-password {
    font-size: 13px;
  }
  .ic-show-password {
    font-size: 18px;
  }
}

.captcha-filed {
  .ic-captcha {
    font-size: 18px !important;
  }
}
