// .form-group {
//   position: relative;
//   margin-bottom: 15px;
//   input.form-control,
//   select.form-control {
//     border-radius: 4px;
//     background: #f8f8f8;
//     padding: 10px 15px;
//     border: 0;
//     height: 44px;
//     line-height: 24px;
//     width: 100%;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//   }
// }

.custom-control {
  position: relative;
  display: block;
  min-height: 16px;
  padding-left: 0;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 22px;
  height: 22px;
  opacity: 0;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 14px;
  padding-left: 30px;
  color: #2a2a2a;
  display: initial;
}
.custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0px;
  display: block;
  width: 18px;
  height: 18px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 2px solid rgba($heading-color, 0.5);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label::after {
  position: absolute;
  top: 0;
  left: 0px;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $checkbox-color;
  background-color: $checkbox-color;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: transparent;
  content: "\e927";
  font-family: "dc" !important;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: $white;
  font-weight: bold;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $checkbox-border-color;
}
.custom-control-input:focus ~ .custom-control-label::before {
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.checkbox-right {
  padding-left: inherit;
  padding-right: 0px;
  .custom-control-input {
    left: inherit;
    right: 0;
  }
  .custom-control-label {
    padding-right: 20px;
  }
  .custom-control-label::before {
    left: inherit;
    right: 0px;
  }
  .custom-control-label::after {
    left: inherit;
    right: 0px;
  }
}

.input-radio {
  margin-left: 20px;
  label {
    font-size: 12px;
    color: $heading-color;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: $white;
        border-radius: 100%;
        border: 2px solid rgba(1, 1, 1, 0.54);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: -3.2px;
        margin-right: 10px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $accent-color;
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border: 2px solid $accent-color;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $accent-color;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border-color: darken(#f4f4f4, 25%);
          background: darken(#f4f4f4, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.loan-table-data {
  .custom-control-label::before {
    width: 14px;
    height: 14px;
  }
  .custom-control-input {
    height: 18px;
    width: 18px;
  }
}
