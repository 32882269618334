header {
  // background: #000;
  height: 48px;
  .header-outer-fixed {
    position: fixed;
    width: 100%;
    background: #000;
    z-index: 9;
  }
  .logo {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
.right-panel-naviganion {
  display: flex;
  align-items: center;
  .nav {
    ul {
      display: flex;
      li {
        margin-right: 30px;
        height: 47px;
        &:last-child {
          margin-right: 26px;
        }
        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $white;
          height: 100%;
          display: flex;
          align-items: center;
        }
        &.dropdownmenu {
          position: relative;
          a {
            &::after {
              content: "\e917";
              font-family: dc !important;
              font-size: 27px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  .notification-btn {
    position: relative;
    padding-left: 23px;
    .notification-count {
      background: $accent-color;
      height: 14px;
      width: 14px;
      border-radius: 100%;
      font-size: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: -1px;
      line-height: 15px;
    }
    &:before {
      content: "";
      height: 22px;
      width: 1px;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      left: 0px;
      top: 5px;
    }
  }
  .user-detail {
    margin-left: 30px;
    ul {
      li {
        height: 47px;
        a {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
    .userarc {
      height: 26px;
      width: 26px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      border-radius: 100%;
      background: $white;
      color: $accent-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .username {
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $white;
        display: block;
      }
      .user-degintion {
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        display: block;
      }
    }
  }
  .language {
    ul {
      li {
        height: 47px;
        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #fff;
          margin-left: 26px;
          height: 100%;
          display: flex;
          align-items: center;
        }
        &.dropdownmenu {
          position: relative;
          a {
            &::after {
              content: "\e917";
              font-family: dc !important;
              font-size: 27px;
              padding-left: 5px;
            }
          }
          .submenu {
            right: 0px;
            min-width: 100px;
            a {
              margin-left: 0px;
              padding-left: 26px;
              &:hover {
              }
            }
          }
        }
      }
    }
  }
}
.user-detail {
  .dropdownmenu {
    .user-type {
      &::after {
        content: "\e917";
        font-family: dc !important;
        font-size: 27px;
        padding-left: 5px;
        color: $white;
        position: relative;
        top: 6px;
      }
    }
  }
}

.menupanel {
  li {
    &.dropdownmenu {
      position: relative;

      .submenu {
        position: absolute;
        background: #fff;
        color: #000;
        display: block;
        flex-direction: column;
        box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
        border-radius: 6px;
        min-width: 260px;
        z-index: 9;
        display: none;
        padding: 10px 0;
        li {
          display: block;
          padding-right: 0px;
          margin-right: 0px;
          height: auto;
          a {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $heading-color;
            padding: 10px 20px;
            display: block;
            &::after {
              display: none;
            }

            &:hover {
              background: rgba(239, 242, 249, 0.5);
            }
          }
        }
      }
      &:hover {
        .submenu {
          display: block;
        }
        a {
          .user-type {
            &::after {
              content: "\e91a";
              font-family: dc !important;
              font-size: 27px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .user-detail {
    li.dropdownmenu .submenu {
      right: 0px;
    }
  }
}
