.table-outer {
    background: $white;
    border-radius: 6px;
    table {
        table-layout: fixed;
        thead {
            tr {
                background: #e3e7f1;
                th {
                    padding: 15px 9px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;
                    &:first-child {
                        border-radius: 6px 0 0 0px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        border-radius: 0px 6px 0px 0;
                        padding-right: 30px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);
                td {
                    padding: 13px 9px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    &:first-child {
                        // padding-top: 19px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        //   border-bottom: 0px;

                        padding-right: 30px;
                    }
                }

                &:hover {
                    background: rgba(239, 242, 249, 0.4);
                }
            }
        }
        .ph-no {
            display: block;
        }
        .name-txt {
            white-space: nowrap;
        }
        th,
        td {
            &:nth-child(1) {
                width: 7.5%;
            }
            &:nth-child(2) {
                width: 12.5%;
            }
            &:nth-child(3) {
                width: 6.5%;
            }
            &:nth-child(4) {
                width: 5.5%;
            }
            &:nth-child(5) {
                width: 9%;
            }
            &:nth-child(6) {
                width: 12%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 11%;
            }
            &:nth-child(9) {
                width: 26%;
            }
            // &:nth-child(10) {
            //     width: 21%;
            // }
        }
    }
    .action-btn {
        display: flex;
        align-items: center;
        .btn-line {
            font-size: 12px;
            height: 29px;
            color: rgba($heading-color, 0.7);
            padding: 0px 10px;
            min-width: auto;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            font-weight: normal;
            margin-right: 10px;
            &:hover {
                background: $accent-color;
                color: $white;
                a {
                    color: $white;
                }
            }
        }
        i {
            font-size: 15px;
            margin-right: 10px;
            cursor: pointer;
            &.ic-email1 {
                font-size: 20px;
            }
            &:hover {
                color: #0bbddd;
            }
        }
        .ic-chat,
        .ic-Call-history {
            position: relative;
            top: 2px;
        }
    }
}
