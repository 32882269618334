.dashboard-outer {
    max-width: 980px;
    margin: 0 auto;
    .heading {
        color: $heading-color;
        text-align: center;
        padding-top: 50px;
        h1 {
            font-weight: 600;
            font-size: 42px;
            line-height: 63px;
        }
        .subheading {
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .dashboard-filter-sec {
        border-radius: 8px;
        background: #eff2f9;
        border-radius: 8px;
        display: flex;
        width: 100%;
        padding: 15px;
        margin-top: 40px;
        fieldset {
            width: 100%;
            margin: 0 5px;
        }
        .date-filed-dashboard {
            .datepicker {
                input {
                    background-color: $white;
                }
                ::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: $heading-color;
                    font-size: 12px;
                }
                ::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $heading-color;
                    font-size: 12px;
                }
                :-ms-input-placeholder {
                    /* IE 10+ */
                    color: $heading-color;
                    font-size: 12px;
                }
                :-moz-placeholder {
                    /* Firefox 18- */
                    color: $heading-color;
                    font-size: 12px;
                }
            }
        }
        .multiselect-dropDown{
            .auction-dropdown__single-value{
                max-width: calc(100% - 36px) !important;
                margin-left: 0px !important;
                padding-left: 2px !important;
            }

            
        }
        .auction-dropdown__value-container--has-value .auction-dropdown__placeholder {
            top: -4px;
            background: $white;
            transition: all 0.15s ease-in-out;
            margin-left: 4px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
        }
    
        .auction-dropdown__control--menu-is-open .auction-dropdown__placeholder {
            top: -4px;
            background: $white;
            transition: all 0.15s ease-in-out;
            margin-left: 4px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
        }
        .auction-dropdown__value-container {
            overflow: inherit !important;
        }
        .btn-submit-reset-auction-tabs {
            display: flex;
            align-items: center;
        }
    }

    .dashboard-card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0px -10px;
        .card {
            border-radius: 15px;
            padding: 20px 10px;
            width: 180px;
            height: 216px;
            margin: 20px 10px 0 10px;
            .roung-bg {
                background: white;
                height: 56px;
                width: 56px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0 auto;
                i {
                    font-size: 25px;
                }
            }
        }
        .color-one {
            background: #bff8fc;
        }
        .color-two {
            background: #fff1d6;
        }
        .color-three {
            background: #ffece2;
        }

        .color-four {
            background: #d2fceb;
        }
        .color-five {
            background: #d8eaff;
        }

        .count-txt {
            font-weight: 500;
            font-size: 42px;
            line-height: 63px;
            text-align: center;
            letter-spacing: 0.5px;
            color: $heading-color;
            display: block;
            text-align: center;
            padding: 13px 0;
        }
        .card-txt {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            display: block;
            color: $heading-color;
            white-space: nowrap;
        }
    }
    ::placeholder {
        font-size: 15px;
        color: rgba($heading-color, 0.7);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }
}

footer {
    padding: 20px 0;
    .footer-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copyright-txt {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 0.4);
        }
    }
    .footer-link {
        display: flex;
        align-items: center;
        li {
            padding-left: 30px;
            position: relative;
            a {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: rgba($heading-color, 0.4);
            }
            &:first-child {
                padding-right: 30px;
                &::after {
                    content: "";
                    width: 1px;
                    height: 17px;
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }
        }
    }
}

.dashboard-bg {
    background: #fff;
    padding-bottom: 100px;
}

.finance-toggle {
    .toggleApp {
        // width: 350px;
        margin: auto;
        margin-left: 10px;
    }

    .ToggleSwitch {
        background-color: #dbdfea;
        height: 18px;
        width: 35px;
        border-radius: 55px;
        &.active {
            background-color: $accent-color;
        }
    }
    .ToggleSwitch .knob {
        position: relative;
        width: 16px;
        height: 16px;
        background: $white;
        border-radius: 50%;
        left: 0em;
        transition: left 0.3s ease-out;
        top: 1px;
        &.active {
            background: $white;
            &::after {
                content: "";
                background: red;
            }
        }
    }
    .ToggleSwitch .knob.active {
        left: 18px;
    }
    .toggle-status {
        margin-left: 45px;
        margin-top: -16px;
        font-size: 12px;
        font-weight: normal;
    }
    .knob ~ .toggle-status {
        color: $heading-color;
    }
    .knob.active ~ .toggle-status {
        color: $heading-color;
    }
}

.multi-post{
    background: #fff;
    border-radius: 10px;
}

.active-label {
    &.material .form-label:before{
        color: $heading-color;
    }
}
