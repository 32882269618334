.tab-outer {
    .tab-bg {
        background: #fff;
        padding: 15px 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .nav-pills {
            display: flex;
            align-items: center;
            .nav-item {
                margin-right: 40px;
                .nav-link {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba($heading-color, 0.87);
                    border-bottom: 2px solid transparent;
                    padding-bottom: 5px;
                    &.active {
                        color: rgba($accent-color, 0.87);
                        font-weight: 500;
                        border-color: #0bbddd;
                    }
                }
            }
        }
        .right-search-panel {
            display: flex;
            align-items: center;
            .css-2b097c-container {
                position: relative;
                box-sizing: border-box;
                width: 240px;
                .css-yk16xz-control,
                .css-1pahdxg-control,
                .css-1n7v3ny-option,
                .css-yt9ioa-option {
                    font-size: 12px;
                    font-weight: 500;
                    color: $heading-color;
                }
                .css-9gakcf-option {
                    background: $accent-color;
                }
            }
        }
    }
}

.search-top-bar {
    margin-left: 10px;
    input {
        height: 42px;
        border: 1px solid #bbbbbb;
        width: 234px;
    }
    i {
        position: absolute;
        left: 20px;
        font-size: 22px;
        top: 9px;
    }
}

.upload-img-input {
    .material {
        .form-label {
            &:before {
                top: 11px;
            }
        }
    }
}
