.auction-top-heading {
    .search-top-bar {
        input {
            width: 300px;
        }
    }
}
.top-filter-sec {
    background: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .btn-submit-reset {
        width: auto;
        display: flex;
        align-items: center;
    }
    fieldset {
        margin-right: 8px;
        width: 100%;
    }
    .css-2b097c-container {
        font-size: 12px;
    }
    .css-g1d714-ValueContainer {
        position: relative;
        top: 1px;
    }
    .css-1hb7zxy-IndicatorsContainer {
        position: relative;
        top: 1px;
    }
}

.auction-monitoring-data-table {
    background: $white;
    border-radius: 6px;
    table {
        table-layout: fixed;
        background: $white;
        border-radius: 6px;
        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;
                th {
                    padding: 15px 10px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;
                    &:first-child {
                        border-radius: 6px 0 0 6px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        border-radius: 0px 6px 6px 0;
                        padding-right: 30px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);
                td {
                    padding: 15px 10px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;
                    vertical-align: top;
                    a {
                        color: $heading-color;
                        text-decoration: underline;
                        color: $heading-color;
                    }

                    &:first-child {
                        // padding-top: 19px;
                        padding-left: 30px;
                    }
                    &:last-child {
                        //   border-bottom: 0px;

                        padding-right: 30px;
                    }
                }

                &:hover {
                    background: rgba(255, 245, 245, 0.7);
                    .btn-line {
                        // background: $accent-color;
                        // color: $white;
                        a {
                            color: $white;
                        }
                    }
                }
                &.bid-nill {
                    background: #fff5f5;
                }
            }
        }
        th,
        td { 
            &:nth-child(1) {
                width: 8%;
            }
            &:nth-child(2) {
                width: 16%;
            }
            &:nth-child(3) {
                width: 14%;
            }
            &:nth-child(4) {
                width: 12%;
            }
            &:nth-child(5) {
                width: 11%;
            }
            &:nth-child(6) {
                width: 9%;
            }
            &:nth-child(7) {
                width: 7%;
            }
            &:nth-child(8) {
                width: 6%;
            }
            &:nth-child(9) {
                width: 16.3%;
            }
            &:nth-child(10) {
                width: 6%;
            }
            &:nth-child(11) {
                width: 7%;
            }
        }
        .custom-control-label {
            padding-left: 0px;
            &:before {
                height: 14px;
                width: 14px;
            }
        }
        .auction-car-detail {
            a {
                display: block;
            }
        }
        .saller-detail {
            span {
                display: block;
            }
        }
    }
    .btn-view {
        border: 0px;
        .btn-line {
            font-weight: normal;
            font-size: 12px;
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: auto;
            height: 29px;
            padding: 0px 7px;
            border-radius: 4px;
        }
    }
}

.bidding-detail-popup {
    .modal-main {
        width: 1130px;
        border-radius: 15px;
        padding: 40px;
        .modal-header {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .heading-txt {
                span {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 30px;
                    color: $heading-color;
                }
            }
        }
    }
    .bid-cancel-download-btn {
        display: flex;
        align-items: center;
        padding-top: 35px;
        margin-bottom: 10px;
        .btn-line {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
        i {
            font-size: 20px;
            margin-right: 10px;
        }
    }
    table {
        table-layout: fixed;
        background: $white;

        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;
                th {
                    padding: 15px 14px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;
                    &:first-child {
                        border-radius: 6px 0 0 6px;
                        padding-left: 18px;
                    }
                    &:last-child {
                        border-radius: 0px 6px 6px 0;
                        padding-right: 10px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);
                td {
                    padding: 24px 14px 20px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;

                    &:first-child {
                        // padding-top: 19px;
                        padding-left: 18px;
                    }
                    &:last-child {
                        //   border-bottom: 0px;

                        padding-right: 10px;
                    }
                    .btn-line {
                        font-size: 12px;
                        color: #666666;
                        font-weight: normal;
                        height: 29px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &:hover {
                    // background: rgba(239, 242, 249, 0.4);
                    .btn-line {
                        background: $accent-color;
                        color: $white;
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
        th,
        td {
            .custom-control-label {
                padding-left: 0px;
                &:before {
                    height: 14px;
                    width: 14px;
                }
            }
            .custom-control-input {
                height: 18px;
                width: 18px;
            }
            .custom-control {
                top: -1px;
            }
            &:nth-child(1) {
                width: 5%;
            }
            &:nth-child(2) {
                width: 20%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 19%;
            }
            &:nth-child(5) {
                width: 12%;
            }
            &:nth-child(6) {
                width: 19%;
            }
            &:nth-child(7) {
                width: 9%;
            }
            &:nth-child(8) {
                width: 16.5%;
            }
            &:nth-child(9) {
                width: 8%;
            }
        }
    }
    .bidder-detail {
        display: flex;
        align-items: center;
        span {
            display: block;
            max-width: calc(100% - 28px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 3px;
        }
    }
}

i.ic-clearclose.cancel-icn {
    font-size: 17px;
    border: 1px solid;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: #0bbddd;
        color: $white;
    }
}

.mob-id-txt {
    display: block;
    span {
        width: 22px;
        display: inline-block;
    }
}

.top-ribben-search {
    .multiselect-dropDown {
        .auction-dropdown__menu {
            width: 100%;
        }
    }
}

.download-csv{
    color:$heading-color;
    &:hover{
        color:$white
    }
}
.confirmation-popup{
    .modal-main {
        width: 500px; 
    }
}
 
