.button {
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 12px;
  @include border-radius(8px);
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}

.btn-gps {
  background: $accent-color;
  color: #fff;
  @include border-radius(12.5px);
  padding: 6px 10px;
  font-size: 13px;

  i {
    color: #fff;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.btn-line {
  @extend .button;
  border: 1px solid $promotional-color;
  color: $heading-color;
  background: #fff;
  padding: 0px 20px;
  &:hover {
    background: $accent-color;
    color: $white !important;
    border-color: $accent-color;
  }
}

.btn-solid {
  @extend .button;
  background: $promotional-color;
  border: 0;
  width: 100%;
  color: #fff;
}

.btn-edit-delete {
  font-size: 12px;
  color: $heading-color;
  height: 29px;
  line-height: 28px;
  padding: 0px 20px;
  border: 1px solid#dbdbdb;
  background: $white;
  color: $heading-color;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background: $accent-color;
  box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
  border-radius: 8px;
  height: 42px;
  line-height: 42px;
  font-weight: 500;
  font-size: 13px;
  color: $white;
  cursor: pointer;
  min-width: 200px;
  border: 1px solid $accent-color;
}
